import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import PortableText from 'react-portable-text';

import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { Hero } from '../components/General/Hero';
import { SupportExternalContactForm } from '../components/Support/SupportExternalContactForm';
import { ListItem } from '../components/CustomStyles/Typography';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		fontFamily: 'Roboto',
		lineHeight: 1.8,
		margin: '1rem 0',
		'& a': {
			color: theme.workwaveBlue,
		},
	},
	header: {
		margin: '1rem 0',
	},
}));

const SupportPage = ({ data, location }) => {
	const support = data.support.edges[0].node;
	const classes = useStyles();
	const {
		hero,
		metaDescription,
		metaTitle,
		_rawPageContent,
		contactForm,
		externalForm,
	} = support;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />

			<WaveDownSVG fill='#FFFFFF' />
			<Container>
				<Grid
					item
					container
					direction='column'
					alignItems='center'
					justifyContent='center'>
					<PortableText
						content={_rawPageContent}
						// className={classes.text}
						serializers={{
							h2: ({ children }) => (
								<Typography
									variant='h2'
									className={classes.header}
									color='primary'>
									{children}
								</Typography>
							),
							normal: ({ children }) => (
								<Typography variant='body1' className={classes.text}>
									{children}
								</Typography>
							),
							li: ({ children }) => (
								<Grid item>
									<ListItem>
										<li>{children}</li>
									</ListItem>
								</Grid>
							),
							emailPhoneLink: (props) => {
								return <a href={props.emailPhoneHref}>{props.children[0]}</a>;
							},
						}}
					/>
				</Grid>
			</Container>

			<SupportExternalContactForm
				formValues={externalForm}
				contactForm={contactForm}
				endPoint='https://workwave.beyondtrustcloud.com/'
			/>
			<Form
				privacy
				modal={false}
				noForm={true}
				location={location}
				pardotUrl='https://go.getservman.com/l/1042281/2023-10-12/65r4qv'
			/>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query SupportQuery {
		support: allSanitySupport {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					_rawPageContent
					contactForm {
						header
					}
					externalForm {
						fieldLabel
						fieldValue
					}
				}
			}
		}
	}
`;

export default SupportPage;
