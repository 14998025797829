import React, { useState } from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	Box,
	Button,
	Container,
	InputBase,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	inputInput: {
		padding: '19px 16px 20px 16px',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		borderRadius: '4px 0 0 4px',
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
		transition: theme.transitions.create('width'),
		[theme.breakpoints.down('xs')]: { fontSize: '.8rem' },
	},
}));

// The devs at support connect are all offshore and are not willing to correct the CORS issues on their end,
// so, for now, this will just point the button at their form fill page. I will leave this code here because
// someday it might be relevant.

export const SupportExternalContactForm = ({ modal, endPoint }) => {
	const classes = useStyles();
	const [input, setInput] = useState({
		code: '',
	});

	const handleChange = (e) => {
		const { value } = e.target;
		setInput({
			code: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!input.code) {
			alert('Field required');
			return;
		}

		try {
			const response = await fetch(endPoint, {
				method: 'POST',
				mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					Code: input.code,
				}),
			});
		} catch (err) {
			//setError(err.response.data);
		} finally {
			//resetForm();
		}
	};

	const resetForm = () => {
		setInput({
			code: '',
		});
	};

	const { code } = input;
	return (
		<Container>
			<div
				style={{
					padding: modal ? '1rem' : 0,
					marginBottom: '12rem', //!modal ? '2rem' : null,
				}}>
				<Grid
					container
					direction='column'
					justifyContent='center'
					alignItems='flex-start'>
					{/* <form
						onSubmit={handleSubmit}
						style={{
							background: 'white',
							width: '80%',
							borderRadius: '6px',
							margin: '2rem 0',
						}}>
						<InputBase
							placeholder='Enter code'
							style={{ width: '80%' }}
							classes={{
								input: classes.inputInput,
							}}
							value={code}
							onChange={(e) => handleChange(e)}
						/> */}
					<a
						href='https://workwave.beyondtrustcloud.com/'
						target='_blank' aria-label="Opens in a new tab"
						style={{ marginBottom: '4rem' }}>
						<Button
							variant='contained'
							color='primary'
							size='large'
							type='submit'
							style={{ marginTop: '2rem' }}>
							ENTER YOUR CODE
						</Button>
					</a>

					{/* </form> */}
				</Grid>
			</div>
		</Container>
	);
};
